import * as IconSet from '@heroicons/react/24/outline'

import { getHexForColor } from '../HubDash/lib/baserow/baserowColors'

import {
  generateBasePrefix,
  generatePseudoRandomDarkerColor,
  isDarkColor,
} from './utils'

interface BaseBadgeProps {
  selectedIcon: string
  iconColor: string
  baseId: number
  baseName: string
  size?: 'small' | 'medium'
}

const BaseBadge: React.FC<BaseBadgeProps> = ({
  selectedIcon,
  iconColor,
  baseId,
  baseName,
  size = 'medium',
}) => {
  const ColorIcon = IconSet[selectedIcon]

  const backgroundColorName = iconColor
    ? iconColor
    : generatePseudoRandomDarkerColor(baseId).name

  const backgroundColor = getHexForColor(backgroundColorName)

  const backgroundColorIsDark = isDarkColor(backgroundColorName)

  const textColor = backgroundColorIsDark ? 'text-white' : 'text-black'

  return (
    <div
      className={`flex ${size === 'small' ? 'h-7 w-7' : 'h-10 w-10'} shrink-0 items-center justify-center rounded-md`}
      style={{
        backgroundColor,
      }}
      data-testid={`base-badge-${baseId}-${backgroundColorName}-${selectedIcon}`}
    >
      {ColorIcon ? (
        <ColorIcon
          className={`${size === 'small' ? 'h-6 w-6' : 'h-8 w-8'} ${textColor}`}
        />
      ) : (
        <p
          className={`${size === 'small' ? 'text-xs' : 'text-sm'} ${textColor}`}
        >
          {generateBasePrefix(baseName)}
        </p>
      )}
    </div>
  )
}

export default BaseBadge
